// import { createPlatonicScene } from "../scenes/PlatonicScene";
// import { createHomeScene } from "../scenes/HomeScene";
// import { createSolutionsScene } from "../scenes/SolutionsScene";
// import { createAcademyScene } from "../scenes/AcademyScene";
// import { createBusinessConsultingScene } from "../scenes/BusinessConsultingScene";
// import { createPixels2PixelsScene } from "../scenes/Pixels2PixelsScene";
//import { createResearchAndDevelopmentScene } from "../scenes/ResearchAndDevelopmentScene";
// import SceneHook from "../hooks/SceneHook/SceneHook";
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../components/Home";
import CustomRouter from "../routes/CustomRouter";
import history from "../routes/history";

export default function PageManager(handleSoundIdChange) {
  const [textures, setTextures] = useState({});
  const [models, setModels] = useState({});
  const [resourcesLoaded, setResourcesLoaded] = useState(false);

  const [showComponentMask, setShowComponentMask] = useState(null);

  function handleToggleComponentMask(isTrue = false) {
    setShowComponentMask(isTrue);
    // setShowComponentMask(showComponentMask => !showComponentMask);
  }
  useEffect(() => {
    if (resourcesLoaded) {
      console.log("Resources Loaded!")
    }
  }, [resourcesLoaded])

  useEffect(() => {

    // const textureLoader = new THREE.TextureLoader();
    // const texturePromises = [
    //   new Promise((resolve) => {
    //     textureLoader.load(sceneBackBusiness, (texture) => {
    //       setTextures((textures) => ({ ...textures, sceneBackBusinessP: texture }));
    //       resolve();
    //     });
    //   }),
    //   new Promise((resolve) => {
    //     textureLoader.load(sceneBackMobileBusiness, (texture) => {
    //       setTextures((textures) => ({ ...textures, sceneBackMobileBusinessP: texture }));
    //       resolve();
    //     });
    //   }),
    //   new Promise((resolve) => {
    //     textureLoader.load(sceneBackMobileLandscapeBusiness, (texture) => {
    //       setTextures((textures) => ({ ...textures, sceneBackMobileLandscapeBusinessP: texture }));
    //       resolve();
    //     });
    //   }),
    // ];

    // Load models
    // const fbxLoader = new FBXLoader();
    // const modelPromises = [
    //   new Promise((resolve) => {
    //     fbxLoader.load(fbxPathFish, (model) => {
    //       setModels((models) => ({ ...models, modelFish: model }));
    //       resolve();
    //     });
    //   }),
    //   new Promise((resolve) => {
    //     fbxLoader.load(fbxPathDiver, (model) => {
    //       setModels((models) => ({ ...models, modelDiver: model }));
    //       resolve();
    //     });
    //   }),
    // ];

    // Wait for all resources to load
    // Promise.all([...texturePromises/*, ...modelPromises*/]).then(() => {
    //   setResourcesLoaded(true);
    // });

  }, []);

  useEffect(() => {
    // Load textures
    let containerWrap = document.querySelector(".container-wrap");
    function onMouseMove(event) {
      if (containerWrap) {
        containerWrap.style.cssText = `
          left: calc(50vw - ${10 * (event.x / window.innerWidth - 0.5)}px);
          top : calc(50vh - ${10 * (event.y / window.innerHeight - 0.5)}px);`;
      } else {
        containerWrap = document.querySelector(".container-wrap");
      }
    }
    window.addEventListener("mousemove", onMouseMove);
    
    const menuLetter01 = document.getElementById('background-black');
    if(menuLetter01)menuLetter01.style.opacity = 0;

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };


  }, []);

  return (
    <div className="container-wrap">
      <div className="scene-wrap">
          <div className="scene-wrap">
            <CustomRouter history={history}>
              <Routes>
                <Route exact path="/" element={<Home textures={textures} />} />
              </Routes>
            </CustomRouter>
          </div>
      </div>
    </div>
  );
}