import { useState, useEffect, useContext } from "react";

import PageManager from "./components/PageManager";
// import {isPhone} from "./util/DeviceType"
import ReactGa from 'react-ga'

// if(isPhone()){
//   if (window.screen.orientation && window.screen.orientation.lock) {
//     window.screen.orientation.lock("portrait-primary");
//   }
// }

ReactGa.initialize('G-B5GP36WSMG');

function App() {
  const [showHomepage, setShowHomepage] = useState(true);
  // const [showPixiCanvas, setShowPixiCanvas] = useState(true);
  // const [textures, setTextures] = useState([]);
  // const [mouseX, setMouseX] = useState(0);
  // const [mouseY, setMouseY] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [soundId, setsoundId] = useState({ id: "base" });
  const [stopSoundId, setStopSoundId] = useState({ id: "nan" });
  const [volume, setVolume] = useState(0);

  const handleVolumeChange = (volume) => {
    setVolume(volume);
  };

  const handleSoundIdChange = (newSoundId) => {
    setsoundId({ id: newSoundId });
  };

  const handleStopSoundIdChange = (stopSoundId) => {
    setStopSoundId({ id: stopSoundId });
  };

  const handleShowHomepage = () => {
    setShowHomepage(true);
  };

  const handleClick = () => {
    setClicked(true);
  };

  return (
    <>
      <div onClick={handleClick}>
        <div id="background-black" className="background-black"></div>
        <PageManager
          setSounds={handleSoundIdChange}
          setStopSounds={handleStopSoundIdChange}
          setSoundsVolume={handleVolumeChange}
          isClicked={clicked}
        />
      </div>
    </>
  );
}

export default App;
