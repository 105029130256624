import React, { useState, useEffect, useContext, useRef } from "react";
import history from "../routes/history";
import HomeThree from "./HomeThree";
import "../styles/home.scss";
import { isPhone } from "../util/DeviceType";
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import en from '../locales/en.json';
import rs from '../locales/rs.json';

i18n.init({
  interpolation: { escapeValue: false },
  lng: 'rs',
  resources: {
    en: { translation: en },
    rs: { translation: rs },
  },
});

export default function Home(props) {


  const handleRouteNextPage = () => {
    document.getElementById("home").style.opacity = "0";
    setTimeout(() => {
      history.push("/selection-page");
    }, 1500);
  };


  return (
    <div className="home" id="home">

      <div id="homepage-content" className="home-header" >
        <I18nextProvider i18n={i18n}>
          <HomeThree textures={props.textures} />
        </I18nextProvider>
      </div>

    </div>
  );
}
